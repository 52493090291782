<template>
  <v-container id="transaction-list" tag="section" fluid grid-list-md>
    <base-material-card
      icon="mdi-cash-plus"
      :title="$t('transactions.bookings list')"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        mobile-breakpoint="500"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        sort-by="rid"
        sort-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.type="{ item }">
          {{ $t("bookings.types." + item.type) }}
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-select
              v-if="!$route.params.user_id"
              v-model="user_id"
              class="search-field"
              item-value="id"
              :label="$t('transactions.user')"
              hide-details
              :items="forSelect(meta.users)"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="client_id"
              class="search-field"
              item-value="id"
              :label="$t('transactions.client_id')"
              hide-details
              :items="forSelect(meta.clients)"
              @change="changeOutput"
            ></v-select>
            <v-text-field
              @change="changeOutput"
              class="search-field"
              v-model="search"
              append-icon="search"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-select
              v-model="type"
              class="search-field"
              item-value="id"
              :label="$t('bookings.type')"
              hide-details
              :items="booking_types"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="gateway_id"
              class="search-field"
              item-value="id"
              :label="$t('transactions.gateway')"
              hide-details
              :items="forSelect(meta.gateways)"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="currency"
              class="search-field"
              item-value="id"
              :label="$t('transactions.currency')"
              hide-details
              :items="currencies"
              @change="changeOutput"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ moment(item.created_at).format("lll") }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudTable";

export default {
  mixins: [crudMixin],
  data() {
    return {
      search: "",
      apiQuery: "/bookings/",
      headers: [
        { text: this.$t("transactions.rid"), align: "start", value: "rid" },
        { text: this.$t("transactions.user"), align: "start", value: "user" },
        { text: this.$t("transactions.client_id"), value: "client" },
        { text: this.$t("transactions.time"), value: "created_at" },
        {
          text: this.$t("transactions.Transaction Id"),
          value: "ref_id",
        },
        { text: this.$t("bookings.type"), value: "type" },
        { text: this.$t("transactions.purpose"), value: "description" },
        { text: this.$t("transactions.gateway"), value: "gateway" },
        {
          text: this.$t("transactions.debit"),
          value: "debit",
          align: "right",
        },
        {
          text: this.$t("transactions.credit"),
          value: "credit",
          align: "right",
        },
        { text: this.$t("transactions.currency"), value: "currency" },
      ],
      loading: true,
      direction: "",
      currency: "EUR",
      currencies: [
        {
          id: "EUR",
          text: "Euro",
        },
      ],
      booking_types: [
        { id: null, text: this.$t("ALL") },
        { id: "payment", text: this.$t("bookings.types.payment") },
        { id: "payout", text: this.$t("bookings.types.payout") },
        {
          id: "return_payment",
          text: this.$t("bookings.types.return_payment"),
        },
        {
          id: "subscr_payment",
          text: this.$t("bookings.types.subscr_payment"),
        },
        { id: "payment_fee", text: this.$t("bookings.types.payment_fee") },
        { id: "non_eu_fee", text: this.$t("bookings.types.non_eu_fee") },
        { id: "return_fee", text: this.$t("bookings.types.return_fee") },
        {
          id: "account_payout",
          text: this.$t("bookings.types.account_payout"),
        },
      ],
      client_id: null,
      clients: [],
      gateway_id: null,
      gateways: [],
      user_id: null,
      users: [],
      type: null,
    };
  },
  mounted() {
    this.user_id = this.options.user_id = this.$route.params.user_id
      ? this.$route.params.user_id
      : null;
    this.get(
      "/bookings/meta?" +
        (this.options.user_id ? "user_id=" + this.options.user_id : "")
    ).then((data) => {
      this.meta = data;
    });
    if (this.$route.query.status) {
      this.status = this.$route.query.status.toUpperCase();
      this.$set(this.options, "status", this.status);
    }
  },
  methods: {
    changeOutput() {
      this.$set(this.options, "search", this.search);
      this.$set(this.options, "currency", this.currency);
      this.$set(this.options, "client_id", this.client_id);
      this.$set(this.options, "user_id", this.user_id);
      this.$set(this.options, "type", this.type);
      this.$set(this.options, "gateway_id", this.gateway_id);
      this.options.page = 1;
    },
    moment,
  },
};
</script>
<style scoped>
.search-field {
  margin-right: 10px;
}
.break-style {
  word-break: break-word;
  max-width: 7.5rem;
}
</style>
